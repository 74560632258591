<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" xs="12">

        <v-toolbar class="transparent elevation-0">
          <v-btn small icon @click="goBack">
            <!-- <icon icon="material-symbols:arrow-back" width="20" height="20"></icon> -->
            <v-icon>mdi mdi-chevron-left</v-icon>
          </v-btn>
          <v-toolbar-title>Convert</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <v-btn icon>
                <icon icon="fe:list-bullet" width="24" height="24" />
            </v-btn> -->
        </v-toolbar>

        <v-tabs v-model="marketType" :show-arrows="false" background-color="#00000000">

          <v-tab v-model="marketType" href="#market"> Market</v-tab>

          <v-tab-item value="market">

            <div class="mt-5">
              <span :class="balanceInUSDT <= 0
                ? 'error--text font-weight-bold'
                : 'success--text font-weight-bold'
                ">Coin Balance:
                {{ balanceInCoin | commaFormat }}
              </span>
            </div>

            <v-row>
              <v-col sm="6" md="4" lg="4">
                <v-form ref="convertForm" lazy-loading>

                  <div class="mt-3 mb-4">
                    <label class="mb-3">From</label>

                    <v-row dense>
                      <v-col cols="12" xs="3" lg="6">
                        <v-select v-model="fromCoin" outlined dense :items="fromCoins" required
                          :disabled="fromCoin === 'USDT'" @keyup="fromCoinAmountChanged(), fetchMinimum(fromCoin)"
                          @input="fetchMinimum(fromCoin)" hide-details></v-select>
                      </v-col>

                      <v-col cols=12 xs="3" lg="6">
                        <v-text-field v-model="fromCoinAmount" outlined dense required :rules="amountRules()"
                          onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"
                          @keyup="fromCoinAmountChanged()">
                          <template v-slot:append>
                            <v-btn text small @click="maxAmount"><span class="font-weight-bold">Max</span>
                            </v-btn>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>

                    <v-col cols="12" align="center">
                      <v-btn icon @click="switchCoins">
                        <icon icon="gg:arrows-exchange-alt-v" width="24" height="24"></icon>
                      </v-btn>
                    </v-col>

                    <v-row dense class="mt-5">
                      <v-col cols="12" xs="3" lg="6">
                        <v-select v-model="toCoin" dense outlined :items="toCoins" required :disabled="toCoin === 'USDT'"
                          hide-details @keyup="toCoinAmountChanged(), fetchMinimum(toCoin)"
                          @input="fetchMinimum(toCoin)"></v-select>
                      </v-col>

                      <v-col cols="12" xs="3" lg="6">
                        <v-text-field v-model="toCoinAmount" dense outlined required :rules="requiredRules()" disabled
                          onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"
                          @keyup="toCoinAmountChanged()">
                          <template v-slot:append>
                            <v-btn text small @click="maxAmount"><span class="font-weight-bold">Max</span>
                            </v-btn>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>

                  </div>

                  <div>
                    <v-btn large block color="primary" style="color: #1C1D28 ;" @click="confirm(), (loader = 'loading')"
                      :loading="loading" :disabled="loading">
                      Convert
                      <template v-slot:loader>
                        <span class="custom-loader">
                          <v-icon light>mdi-cached</v-icon>
                        </span>
                      </template>
                    </v-btn>
                  </div>

                </v-form>
              </v-col>
            </v-row>

          </v-tab-item>

          <v-tab v-model="marketType" href="#limit"> Limit</v-tab>
          <v-tab-item value="limit">

            <div class="mt-5">
              <span :class="balanceInUSDT <= 0
                ? 'error--text font-weight-bold'
                : 'success--text font-weight-bold'
                ">Coin Balance:
                {{ balanceInCoin | commaFormat }}
              </span>
            </div>

            <v-row>

              <v-col sm="6" md="4" lg="4">
                <v-form ref="convertForm" lazy-loading>

                  <div class="mt-3 mb-4">
                    <label class="mb-3">From</label>

                    <v-row dense>
                      <v-col cols="12" xs="3" lg="6">
                        <v-select v-model="fromCoin" dense outlined :items="fromCoins" required
                          :disabled="fromCoin === 'USDT'" @keyup="fromCoinAmountChanged(), fetchMinimum(fromCoin)"
                          hide-details></v-select>
                      </v-col>

                      <v-col cols="12" xs="3" lg="6">
                        <v-text-field v-model="fromCoinAmount" dense outlined required :rules="amountRules()"
                          onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"
                          @keyup="fromCoinAmountChanged()">
                          <template v-slot:append>
                            <v-btn text small @click="maxAmount"><span class="font-weight-bold">Max</span>
                            </v-btn>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>

                    <v-col cols="12" align="center">
                      <v-btn icon @click="switchCoins">
                        <icon icon="gg:arrows-exchange-alt-v" width="24" height="24"></icon>
                      </v-btn>
                    </v-col>

                    <v-row dense class="mt-5">
                      <v-col cols="12" xs="3" lg="6">
                        <v-select v-model="toCoin" dense outlined :items="toCoins" required :disabled="toCoin === 'USDT'"
                          hide-details @keyup="toCoinAmountChanged(), fetchMinimum(toCoin)"></v-select>
                      </v-col>

                      <v-col cols="12" xs="3" lg="6">
                        <v-text-field v-model="toCoinAmount" dense outlined required :rules="requiredRules()"
                          onkeypress="return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46"
                          @keyup="toCoinAmountChanged()">
                          <template v-slot:append>
                            <v-btn text @click="maxAmount"><span class="font-weight-bold">Max</span>
                            </v-btn>
                          </template>
                        </v-text-field>
                      </v-col>
                    </v-row>
                  </div>

                  <div>
                    <v-btn block large color="primary" @click="confirm(), (loader = 'loading')" :loading="loading"
                      :disabled="loading" style="color: #1C1D28 ;">
                      Convert
                      <template v-slot:loader>
                        <span class="custom-loader">
                          <v-icon light>mdi-cached</v-icon>
                        </span>
                      </template>
                    </v-btn>
                  </div>
                </v-form>
              </v-col>
            </v-row>

          </v-tab-item>
        </v-tabs>

        <v-dialog v-model="confirmDialog" persistent width="400px">
          <v-card class="py-2" width="400px">
            <v-card-title>
              <v-toolbar class="elevation-0 transparent">
                <v-spacer></v-spacer>
                <v-toolbar-title>Confirmation</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
            </v-card-title>
            <v-card-text>
              <v-form ref="confirmationForm" lazy-loading>
                <v-row>
                  <v-col cols="12">
                    <label>Transaction Password</label>
                    <v-text-field class="mt-3" dense v-model="password"
                      :append-icon="visible ? 'mdi-eye-off' : 'mdi-eye'" @click:append="visible = !visible"
                      :rules="passwordRules()" :type="visible ? 'text' : 'password'"
                      :error-messages="errorMessage.password" outlined />
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="confirmDialog = false">
                Cancel
              </v-btn>
              <v-btn color="primary" class="px-4" @click="convert(), (loader = 'loading')" :loading="loading"
                :disabled="loading">
                Confirm
                <template v-slot:loader>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import store from "@/store";
export default {
  data: () => ({
    loading: false,
    visible: false,
    marketType: "market",
    fromCoinAmount: 0,
    toCoinAmount: 0,
    password: "",
    fromCoin: "USDT",
    toCoin: "BTC",
    minimumSell: 0,
    minimumBuy: 0,
    refreshKey: 0,
    errorMessage: {
      password: "",
      
    },
    confirmDialog: false,
  }),
  computed: {
    balanceToDisplay() {
      return this.priceType == "TOTAL"
        ? this.buyBalanceInCoin
        : this.buyBalanceInUSDT
    },



    buyBalanceInUSDT() {
      let wallets = this.$store.getters.wallet.wallet;
      return wallets.find((item) => item.name === "USDT").usdt_value;
    },

    buyBalanceInCoin() {
      let wallets = this.$store.getters.wallet.wallet;
      let balance =
        wallets.find((item) => item.name === "USDT").usdt_value /
        this.valuePerUnit;
      return balance;
    },


    fromCoins() {
      this.refreshKey;
      //Extract coins from store get only the name
      let coins = this.$store.getters.marketCoins.map((coin) => coin.name);
      //Remove duplicates
      coins = coins.filter((coin, index) => coins.indexOf(coin) === index);
      //Add USDT
      coins.push("USDT");
     if(this.toCoin === 'USDT')
     {
        coins.pop("USDT")
     }
      return coins;
    },

    toCoins() {
      this.refreshKey;
      //Extract coins from store get only the name
      let coins = this.$store.getters.marketCoins.map((coin) => coin.name);
      //Remove duplicates
      coins = coins.filter((coin, index) => coins.indexOf(coin) === index);
      //Add USDT
      coins.push("USDT");
      if(this.fromCoin === 'USDT')
     {
      coins.pop("USDT")
     }
      return coins;
    },


    marketCoins() {
      return this.$store.getters.marketCoins;
    },
    delegateType() {
      return this.fromCoin === "USDT" ? "BUY" : "SELL";
    },
    numberOfOrder() {
      return this.delegateType === "BUY"
        ? this.toCoinAmount
        : this.fromCoinAmount;
    },
    valuePerUnit() {
      let value = 0;
      if (this.fromCoin === "USDT") {
        value = this.marketCoins.find(
          (coin) => coin.name === this.toCoin
        ).value;
      } else {
        value = this.marketCoins.find(
          (coin) => coin.name === this.fromCoin
        ).value;
      }
      return value;
    },
    balanceInUSDT() {
      let wallets = this.$store.getters.wallet.wallet;
      return wallets.find((item) => item.name === this.fromCoin).usdt_value;
    },
    balanceInCoin() {
      let wallets = this.$store.getters.wallet.wallet;
      return wallets.find((item) => item.name === this.fromCoin).coin_value;
    },
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    fetchMinimum(coin) {
      this.$axios
        .get(`https://api.uccoin.co/api/v3/getOrder?symbol=${coin}`)
        .then((response) => {
          this.minimumSell = parseFloat(response.data.price.minimum_sell);
          this.minimumBuy = parseFloat(
            response.data.price.account_balance_limit_spot
          );
        });
    },

    fetchLimit() {
      this.$axios
        .get(`https://api.uccoin.co/api/v3/getOrder?symbol=5`)
        .then((response) => {
          this.minimumSell = parseFloat(response.data.price.minimum_sell);
          this.minimumBuy = parseFloat(
            response.data.price.account_balance_limit_spot
          );
        });
    },
    switchCoins() {
      let temp = this.fromCoin;
      this.fromCoin = this.toCoin;
      this.toCoin = temp;

      temp = this.fromCoinAmount;
      this.fromCoinAmount = this.toCoinAmount;
      this.toCoinAmount = temp;
      this.refreshData()
    },

    refreshData() {
      // Force recomputation by changing refreshKey
      this.refreshKey++;
    },
    fromCoinAmountChanged() {
      if (this.fromCoinAmount === "" || this.fromCoinAmount === 0) {
        this.toCoinAmount = 0;
        return;
      }
      let toCoin = null;
      let fromCoin = null;
      if (this.fromCoin === "USDT") {
        toCoin = this.marketCoins.find((coin) => coin.name === this.toCoin);
        if (toCoin) {
          this.toCoinAmount = this.fromCoinAmount / toCoin.value;
        }
      } else {
        fromCoin = this.marketCoins.find((coin) => coin.name === this.fromCoin);
        if (fromCoin) {
          this.toCoinAmount = this.fromCoinAmount * fromCoin.value;
        }
      }
    },
    toCoinAmountChanged() {
      if (this.toCoinAmount === "" || this.toCoinAmount === 0) {
        this.fromCoinAmount = 0;
        return;
      }
      let toCoin = null;
      let fromCoin = null;
      if (this.toCoin === "USDT") {
        fromCoin = this.marketCoins.find((coin) => coin.name === this.fromCoin);
        if (fromCoin) {
          this.fromCoinAmount = this.toCoinAmount / fromCoin.value;
        }
      } else {
        toCoin = this.marketCoins.find((coin) => coin.name === this.toCoin);
        if (toCoin) {
          this.fromCoinAmount = this.toCoinAmount * toCoin.value;
        }
      }
    },

    amountRules() {
      if (this.fromCoin == 'USDT') {
        return [
          (v) => !!v || "Amount is required",
          (v) => (v >= Number(this.minimumBuy)) || `Amount must be greater than minimum buy amount (${this.minimumBuy})`,
          (v) => (v <= Number(this.balanceInCoin)) || `Insufficient balance (${this.balanceInCoin})`,
        ];
      }
      else {
        return [
          (v) => !!v || "Amount is required",
          (v) => (v >= Number(this.minimumSell)) || `Amount must be greater than minimum buy amount (${this.minimumSell})`,
          (v) => (v <= Number(this.balanceInCoin)) || `Insufficient balance (${this.balanceInCoin})`,
        ];
      }

    },

    sellAmountRules() {
      if (this.fromCoin == 'USDT') {

      }
      else {
        return [
          (v) => !!v || "Amount is required",
          (v) => (v >= Number(this.minimumBuy)) || `Amount must be greater than minimum buy amount (${this.minimumBuy})`,
          (v) => (v <= this.balanceInUSDT) || `Insufficient balance (${this.balanceInUSDT})`,
        ];
      }
    },
    // amountRules() {
    //   return this.delegateType === "SELL"
    //     ? [
    //       (v) => !!v || "Amount is required",
    //       (v) =>
    //         v >= this.minimumSell ||
    //         `Amount must be greater than minimum sell amount (${this.minimumSell})`,
    //       (v) =>
    //         v <= this.balanceInCoin ||
    //         `Insufficient balance (${this.balanceInCoin})`,
    //     ]
    //     : [
    //       (v) => !!v || "Amount is required",
    //       (v) =>
    //         v >= this.minimumBuy ||
    //         `Amount must be greater than minimum buy amount (${this.minimumBuy})`,
    //       (v) =>
    //         v <= this.balanceInCoin ||
    //         `Insufficient balance (${this.balanceInCoin})`,
    //     ];
    // },
    requiredRules() {
      return [
        (v) => !!v || "Amount is required",
      ];
    },
    passwordRules() {
      return [(v) => !!v || "Password is required"];
    },
    confirm() {
      if (this.$refs.convertForm.validate()) this.confirmDialog = true;
    },
    convert() {
      if (this.$refs.confirmationForm.validate()) {
        this.loading = true;
        this.$axios
          .get("https://api.uccoin.co/api/v2/my-order", {
            params: {
              name: this.delegateType === "BUY" ? this.toCoin : this.fromCoin,
              delegate_type: this.delegateType,
              number_of_order: this.numberOfOrder,
              total:
                this.delegateType === "BUY"
                  ? this.fromCoinAmount
                  : this.toCoinAmount,
              order_per_unit: this.valuePerUnit,
              trade_type: "convert",
              market_type: this.marketType.toLocaleUpperCase(),
              transaction_password: this.password,
            },
          })
          .then((response) => {
            this.loading = false;
            if (response.data.message == "success") {
              this.$swal({
                title: "Success",
                text: "Order created successfully",
                icon: "success",
                timer: 3000,
                buttons: false,
              });
              this.$store.dispatch("setWallet");
              this.$router.push("/home");
            } else if (response.data.message === "wrong_transaction_password") {
              this.$swal({
                title: "Error",
                text: "Wrong transaction password",
                icon: "error",
                timer: 3000,
                buttons: false,
              });
              this.errorMessage.password = "Wrong transaction password";
            } else {
              this.$swal({
                title: "Error",
                text: "Something went wrong",
                icon: "error",
                timer: 3000,
                buttons: false,
              });
            }
          })
          .catch((error) => {
            this.loading = false;
            this.$swal({
              title: "Error",
              text: error.response.data.message,
              icon: "error",
              timer: 3000,
              buttons: false,
            });
          });
      }
    },
    maxAmount() {
      this.fromCoinAmount = this.balanceInCoin;
      this.fromCoinAmountChanged();
    },
  },
  mounted() {
    this.fetchMinimum("BTC");
  },

  beforeRouteEnter(to, from, next) {
    const isAuthenticated = store.getters.isAuthenticated;
    if (isAuthenticated) {
      next();
    } else {
      next("/signin");
    }
  },
};
</script>
<style>
.theme--dark.v-tabs-items {
  background-color: transparent !important;
}

/* Button Spinner */
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
